import React from 'react'
import LOCALE from '../AppLocale'
import CONSTANTS from './checkout-constants'

export default class Shipping extends React.Component {
    constructor(props) {
        super(props);

        const shipping = props.shipping;
        this.state = {
            isValid: {
                name: shipping.name ? CONSTANTS.valid : CONSTANTS.required,
                address: shipping.address ? CONSTANTS.valid : CONSTANTS.required,
                zip: shipping.zip ? CONSTANTS.valid : CONSTANTS.required,
                city: shipping.city ? CONSTANTS.valid : CONSTANTS.required,
                country: shipping.country ? CONSTANTS.valid : CONSTANTS.required
            },
            name: shipping.name ? shipping.name : '',
            address: shipping.address ? shipping.address : '',
            zip: shipping.zip ? shipping.zip : '',
            city: shipping.city ? shipping.city : '',
            country: shipping.country ? shipping.country : ''
        }

        this.nameChanged = this.nameChanged.bind(this);
        this.addressChanged = this.addressChanged.bind(this);
        this.zipChanged = this.zipChanged.bind(this);
        this.cityChanged = this.cityChanged.bind(this);
        this.countryChanged = this.countryChanged.bind(this);
        this.shippingChanged = this.shippingChanged.bind(this);
    }

    componentDidMount() {
        this.shippingChanged();
    }

    shippingChanged() {
        const shipping = this.state;
        const isValid = shipping.name !== '' && shipping.address !== '' && shipping.zip !== '' && shipping.city !== '' && shipping.country !== '';
        this.props.actions.shippingChanged({
            name: shipping.name,
            address: shipping.address,
            zip: shipping.zip,
            city: shipping.city,
            country: shipping.country,
            isValid
        });
    }

    nameChanged(e) {
        const name = e.target.value;
        const isValid = name ? CONSTANTS.valid : CONSTANTS.required;
        this.setState({ name, isValid: { ...this.state.isValid, name: isValid } });
    }

    addressChanged(e) {
        const address = e.target.value;
        const isValid = address ? CONSTANTS.valid : CONSTANTS.required;
        this.setState({ address, isValid: { ...this.state.isValid, address: isValid } });
    }

    zipChanged(e) {
        let zip = e.target.value;
        const nonNumericRegex = /[^0-9.]+/g;
        zip = zip.replace(nonNumericRegex, '');

        const isValid = zip ? CONSTANTS.valid : CONSTANTS.required;
        this.setState({ zip, isValid: { ...this.state.isValid, zip: isValid } });
    }

    cityChanged(e) {
        const city = e.target.value;
        const isValid = city ? CONSTANTS.valid : CONSTANTS.required;
        this.setState({ city, isValid: { ...this.state.isValid, city: isValid } });
    }

    countryChanged(e) {
        const country = e.target.value;
        const isValid = country ? CONSTANTS.valid : CONSTANTS.required;
        this.setState({ country, isValid: { ...this.state.isValid, country: isValid } });
    }

    render() {
        const { appLocale } = this.props;
        const { name, address, zip, city, country, isValid } = this.state;

        return (
            <div className="Shipping">
                <h1>{LOCALE[appLocale].shipping}</h1>
                <div className="textbox">
                    <label>{LOCALE[appLocale].name}</label><input type="text" value={name} onChange={this.nameChanged} onBlur={this.shippingChanged} />
                    <span className={isValid.name === CONSTANTS.valid ? 'required valid icon-check' : 'required'}>{isValid.name}</span>
                </div>
                <div className="textbox">
                    <label>{LOCALE[appLocale].address}</label><input type="text" value={address} onChange={this.addressChanged} onBlur={this.shippingChanged} />
                    <span className={isValid.address === CONSTANTS.valid ? 'required valid icon-check' : 'required'}>{isValid.address}</span>
                </div>
                <div className="textbox">
                    <label>{LOCALE[appLocale].postNumber}</label><input type="tel" value={zip} onChange={this.zipChanged} onBlur={this.shippingChanged} />
                    <span className={isValid.zip === CONSTANTS.valid ? 'required valid icon-check' : 'required'}>{isValid.zip}</span>
                </div>
                <div className="textbox">
                    <label>{LOCALE[appLocale].city}</label><input type="text" value={city} onChange={this.cityChanged} onBlur={this.shippingChanged} />
                    <span className={isValid.city === CONSTANTS.valid ? 'required valid icon-check' : 'required'}>{isValid.city}</span>
                </div>
                <div className="textbox">
                    <label>{LOCALE[appLocale].country}</label><input type="text" value={country} onChange={this.countryChanged} onBlur={this.shippingChanged} />
                    <span className={isValid.country === CONSTANTS.valid ? 'required valid icon-check' : 'required'}>{isValid.country}</span>
                </div>
            </div>
        )
    }
}