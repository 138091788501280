import _ from 'lodash'
import api from '../api/webshop-api'
import { CATEGORIES } from '../components/products/product-constants'

// CHECKOUT
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST'
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS'
export const CHECKOUT_FAILED = 'CHECKOUT_FAILED'

export function checkout() {
    return (dispatch, getState) => {
        const { checkout, order, webshop } = getState();
        const fees = webshop.fees.transaction + (order.isDigital ? 0 : (order.product.category == CATEGORIES.GIFTCARD ? webshop.fees.shipping : webshop.fees.shipping + (webshop.fees.handling * order.quantity)));
        const payWithReepay = _.startsWith(webshop.organizer.dibs.merchantId, 'priv_'); 
        const checkoutApiModel = {
            organizer: webshop.organizer,
            order: {
                productId: order.product.id,
                productName: order.product.name,
                quantity: order.quantity,
                amount: order.product.price,
                designId: !order.isDigital ? order.designId : null,
                designName: order.designId && !order.isDigital ? _.find(webshop.designs, (design) => { return design.id == order.designId }).name : null,
                message: order.message,
                adultQuantity: order.adultQuantity,
                childQuantity: order.childQuantity,
                isImax: order.isImax,
                totalPrice: order.totalPrice
            },
            fees,
            creditCardType: !payWithReepay ? checkout.payment.creditCard.code : '',
            customer: checkout.customer,
            shipping: !order.isDigital ? checkout.shipping : null
        }
        dispatch(checkoutRequest());
        return api.checkout(checkoutApiModel)
            .then(dibs => dibs.parameters ? dispatch(checkoutSuccess(dibs)) : dispatch(checkoutFailed()), error => dispatch(checkoutFailed()))
            .then(result => { return result.type === CHECKOUT_SUCCESS });
    }
}

const checkoutRequest = () => {
    return {
        type: CHECKOUT_REQUEST,
        requestedAt: Date.now(),
    }
}

const checkoutSuccess = (dibs) => {
    return {
        type: CHECKOUT_SUCCESS,
        succeededAt: Date.now(),
        dibs
    }
}

const checkoutFailed = () => {
    return {
        type: CHECKOUT_FAILED,
        failedAt: Date.now(),
        error: 'Vi kunne ikke gennemføre købet. Prøv igen senere.'
    }
}

// COMPLETE CHECKOUT
export const COMPLETE_CHECKOUT_REQUEST = 'COMPLETE_CHECKOUT_REQUEST'
export const COMPLETE_CHECKOUT_SUCCESS = 'COMPLETE_CHECKOUT_SUCCESS'
export const COMPLETE_CHECKOUT_FAILED = 'COMPLETE_CHECKOUT_FAILED'

export function completeCheckout(orderId, dibsTransactionId) {
    return (dispatch, getState) => {
        dispatch(completeCheckoutRequest());

        const completeCheckoutApiModel = {
            organizerId: getState().webshop.organizer.id,
            orderId,
            dibsTransactionId
        }

        return api.completeCheckout(completeCheckoutApiModel)
            .then(receipt => receipt ? dispatch(completeCheckoutSuccess(receipt)) : dispatch(completeCheckoutFailed()), error => dispatch(completeCheckoutFailed()));
    }
}

const completeCheckoutRequest = () => {
    return {
        type: COMPLETE_CHECKOUT_REQUEST,
        requestedAt: Date.now(),
    }
}

const completeCheckoutSuccess = (receipt) => {
    return {
        type: COMPLETE_CHECKOUT_SUCCESS,
        succeededAt: Date.now(),
        receipt
    }
}

const completeCheckoutFailed = () => {
    return {
        type: COMPLETE_CHECKOUT_FAILED,
        failedAt: Date.now(),
        error: 'Vi kunne ikke gennemføre købet. Prøv igen senere.'
    }
}

// CHANGE HANDLERS
export const CUSTOMER_CHANGED = 'CUSTOMER_CHANGED'
export const SHIPPING_CHANGED = 'SHIPPING_CHANGED'
export const PAYMENT_CHANGED = 'PAYMENT_CHANGED'
export const REMEMBER_ME_CHANGED = 'REMEMBER_ME_CHANGED'

export const customerChanged = (newCustomer) => {
    return {
        type: CUSTOMER_CHANGED,
        customer: newCustomer
    }
}

export const shippingChanged = (newShipping) => {
    return {
        type: SHIPPING_CHANGED,
        shipping: newShipping
    }
}

export const paymentChanged = (newPayment) => {
    return {
        type: PAYMENT_CHANGED,
        payment: newPayment
    }
}

export const rememberMeChanged = (newRememberMe) => {
    return {
        type: REMEMBER_ME_CHANGED,
        rememberMe: newRememberMe
    }
}

// RESET
export const RESET_CHECKOUT = 'RESET_CHECKOUT';
export const resetCheckout = () => {
    return {
        type: RESET_CHECKOUT
    }
}