export default {
    da: {
        // General
        next: 'Næste  ❯',
        back: '❮  Tilbage',
        startOver: 'Start forfra',
        pictureOf: 'Billede af: ',
        currency: 'kr.',
        loading: 'Indlæser...',

        // Summary
        yourOrder: 'Din Bestilling',
        totalPrice: 'Pris i alt',
        fee: 'gebyr',

        // Product Details
        quantity: 'Antal',
        amount: 'Beløb',
        amountOf: 'á',
        giftBoxWithShipping: 'Gaveæske med valgfrit tema inklusiv forsendelse',
        giftCardWithShipping: 'Fysisk gavekort inklusiv forsendelse',
        adult: 'Voksen',
        child: 'Barn',
        imax: 'IMAX',
        message: 'Ønsker du gaven sendt til en anden modtager – kan du her skrive en personlig hilsen der vedlægges gaveæsken. <br/> Du bedes være opmærksom på, at der er 1-3 hverdages leveringstid på fysiske gavekort. <br /> <b>Sidste dag for bestilling af gaver inden jul er d. 18. december 2022.</b>',

        errorMinAmount: 'Du skal mindst angive INSERT_MIN_AMOUNT DKK for at fortsætte',
        errorMinTicket: 'Du skal mindst vælge en billet for at forsætte',
        errorMinDefault: 'Du skal mindst vælge én for at forsætte',

        // Checkout
        yourInformation: 'Dine oplysninger',
        name: 'Navn',
        phone: 'Telefon',
        email: 'Email',
        repeatEmail: 'Gentag email',
        rememberMe: 'Husk mig',

        shipping: 'Leveringsadresse',
        address: 'Adresse',
        postNumber: 'Postnr.',
        city: 'By',
        country: 'Land',

        payment: 'Vælg betalingsmetode',
        foreign: 'Udenlandsk',
        acceptConditions: 'Ja, jeg accepterer INSERT_ORGANIZER_NAME/eBillets <a target="_blank" href=INSERT_ORGANIZER_CONDITIONS>betalingsbetingelser</a>',

        errorName: 'Skal være udfyldt',
        errorPhone: 'Skal bestå af 8 tal',
        errorEmail: 'Skal være en gyldig email',
        errorRepeatEmail: 'De indtastede e-mails er ikke ens',
        errorRequiredInputs: 'Du skal udfylde alle felter og valg, før du kan fortsætte',
        errorMinProduct: 'Du skal vælge et produkt, før du kan fortsætte',

        //Receipt
        receipt: 'Købskvittering',
        print: 'Print kvittering',
        digitalOrder: 'Din pakke består af følgende dele',
        physicalOrder: 'Pakken vil blive sendt til leveringsadressen så hurtigt som muligt.',
        dear: 'Kære',
        thankYouText: 'Tak fordi du har handlet hos',
        emailText: 'Du modtager en email med din bestilling indenfor kort tid.',
        download: 'Hent bestilling',
        downloadText: 'Du kan også trykke på \"Hent bestilling\" knappen for at få ordren med det samme.',
        orderNumber: 'Ordrenummer'
    },

    en: {
        // General
        next: 'Next  ❯',
        back: '❮  Back',
        startOver: 'Start over',
        pictureOf: 'Picture of: ',
        currency: 'DKK',
        loading: 'Loading...',

        // Summary
        yourOrder: 'Your order',
        totalPrice: 'Total price',
        fee: 'fee',

        // Product Details
        quantity: 'Quantity',
        amount: 'Amount',
        amountOf: 'of',
        giftBoxWithShipping: 'Gift box with optional theme including shipping.',
        giftCardWithShipping: 'Physical gift card including shipping',
        adult: 'Adult',
        child: 'Child',
        imax: 'IMAX',
        message: 'Are you sending this gift to someone - you can include a personal message that will be put in the gift box',

        errorMinAmount: 'Amount has to be minimum INSERT_MIN_AMOUNT DKK to continue',
        errorMinTicket: 'Choose at least one ticket to continue',
        errorMinDefault: 'Choose at least one to continue',

        // Checkout
        yourInformation: 'Your information',
        name: 'Name',
        phone: 'Phone',
        email: 'Email',
        repeatEmail: 'Repeat email',
        rememberMe: 'Remember me',

        shipping: 'Shipping Address',
        address: 'Adresse',
        postNumber: 'ZIP code',
        city: 'City',
        country: 'Country',

        payment: 'Payment method',
        foreign: 'Foreign',
        acceptConditions: 'Yes, I accept the INSERT_ORGANIZER_NAME/eBillets <a target="_blank" href=INSERT_ORGANIZER_CONDITIONS>sales conditions</a>',

        errorName: 'Has to be filled out',
        errorPhone: 'Has to be 8 numbers',
        errorEmail: 'Has to be a valid email',
        errorRepeatEmail: 'The entered emails are not the same',
        errorRequiredInputs: 'You have to fill out all the inputs and choices before you can continue',
        errorMinProduct: 'You have to chose a product before you can continue',

        //Receipt
        receipt: 'Receipt',
        print: 'Print receipt',
        digitalOrder: 'Your package contains the following parts',
        physicalOrder: 'Your package will be sent to the shipping address as fast as possible.',
        dear: 'Dear',
        thankYouText: 'Thank you for shopping at',
        emailText: 'You will receive an email with your order within a short amount of time.',
        download: 'Download order',
        downloadText: 'You can also click the \"Download order\" button to get your order immediately.',
        orderNumber: 'Order number'
    }
}