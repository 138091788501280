export default {
    valid: '',
    required: '*',

    errors: {
        required: 'Skal være udfyldt',
        phone: 'Skal bestå af 8 tal',
        email: 'Skal være en gyldig email',
        repeatEmail: 'De indtastede e-mails er ikke ens'
    }
}