import localStorageUtils from '../utilities/localstorage-utilities'
import {
    CHECKOUT_REQUEST,
    CHECKOUT_SUCCESS,
    CHECKOUT_FAILED,
    COMPLETE_CHECKOUT_REQUEST,
    COMPLETE_CHECKOUT_SUCCESS,
    COMPLETE_CHECKOUT_FAILED,
    CUSTOMER_CHANGED,
    SHIPPING_CHANGED,
    PAYMENT_CHANGED,
    MESSAGE_CHANGED,
    REMEMBER_ME_CHANGED,
    RESET_CHECKOUT
} from '../actions/checkout-actions'
import localstorageUtilities from '../utilities/localstorage-utilities';

export default function checkout(
    state = {
        customer: {},
        shipping: {},
        payment: {},
        dibs: { parameters: {} },
        rememberMe: false,
        conditionsAccepted: false,
        completed: false,
        receipt: { checkout: {}, vouchers: [] },
        isFetching: false,
        error: null
    }, action) {
    switch (action.type) {
        case CHECKOUT_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case CHECKOUT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.receivedAt,
                dibs: action.dibs
            }
        case CHECKOUT_FAILED:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case COMPLETE_CHECKOUT_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case COMPLETE_CHECKOUT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.succeededAt,
                completed: true,
                receipt: action.receipt
            }
        case COMPLETE_CHECKOUT_FAILED:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case CUSTOMER_CHANGED: {
            if (state.rememberMe) setCustomerInLocalStorage(action.customer);
            return {
                ...state,
                customer: action.customer
            }
        }
        case SHIPPING_CHANGED: {
            if (state.rememberMe) setShippingInLocalStorage(action.shipping);
            return {
                ...state,
                shipping: action.shipping
            }
        }
        case PAYMENT_CHANGED:
            if (state.rememberMe) setPaymentInLocalStorage(action.payment);
            return {
                ...state,
                payment: action.payment
            }
        case REMEMBER_ME_CHANGED:
            if (action.rememberMe) {
                const customer = state.customer;
                const shipping = state.shipping;
                const payment = state.payment;

                localStorageUtils.setItem('checkout', {
                    rememberMe: true,
                    customer: {
                        name: customer.name,
                        phone: customer.phone,
                        email: customer.email,
                        repeatEmail: customer.repeatEmail,
                    },
                    shipping: {
                        name: shipping.name,
                        address: shipping.address,
                        zip: shipping.zip,
                        city: shipping.city,
                        country: shipping.country
                    },
                    payment: {
                        creditCard: payment.creditCard,
                        conditionsAccepted: payment.conditionsAccepted
                    }
                });
            }
            else localStorageUtils.removeItem('checkout');
            return {
                ...state,
                rememberMe: action.rememberMe
            }
        case RESET_CHECKOUT:
            return {
                ...state,
                receipt: { checkout: {}, vouchers: [] },
                completed: false
            }
        default:
            return state
    }
}

function setCustomerInLocalStorage(customer) {
    const checkout = localstorageUtilities.getItem('checkout');
    localStorageUtils.setItem('checkout', {
        ...checkout,
        customer: {
            name: customer.name,
            phone: customer.phone,
            email: customer.email,
            repeatEmail: customer.repeatEmail
        }
    });
}

function setShippingInLocalStorage(shipping) {
    const checkout = localstorageUtilities.getItem('checkout');
    localStorageUtils.setItem('checkout', {
        ...checkout,
        shipping: {
            name: shipping.name,
            address: shipping.address,
            zip: shipping.zip,
            city: shipping.city,
            country: shipping.country
        }
    });
}

function setPaymentInLocalStorage(payment) {
    const checkout = localstorageUtilities.getItem('checkout');
    localStorageUtils.setItem('checkout', {
        ...checkout,
        payment: {
            creditCard: payment.creditCard,
            conditionsAccepted: payment.conditionsAccepted
        }
    });
}