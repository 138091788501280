import React from 'react'
import _ from 'lodash'
import LOCALE from '../../AppLocale'

export default class CreditCards extends React.Component {

    constructor() {
        super();
        this.creditCardChanged = this.creditCardChanged.bind(this);
    }

    creditCardChanged(e) {
        const creditCardCode = e.target.value;
        this.props.creditCardChanged({ code: creditCardCode });
    }

    generateOptions() {
        const { appLocale, creditCards, selectedCreditCard } = this.props;

        return _.map(creditCards, (card, i) => {
            const international = card.international ? `${LOCALE[appLocale].foreign} ` : null;
            return <label key={i}>
                <input name="CreditCards" type="radio" defaultChecked={selectedCreditCard && selectedCreditCard.code === card.code} value={card.code} />
                <img src={card.image} /> {international}{card.name}
            </label>
        });
    }

    render() {
        const options = this.generateOptions();
        const { selectedCreditCard } = this.props;
        return (
            <div className="CreditCards" onChange={this.creditCardChanged} value={selectedCreditCard ? selectedCreditCard.code : ''}>
                {options}
            </div>
        )
    }
}