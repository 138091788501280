import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import LOCALE from '../../AppLocale'
import { ROUTES } from '../../navigation/route-constants'
import { completeCheckout } from '../../../actions/checkout-actions'
import { getParams } from '../../../utilities/location-utilities'
import Error from '../../overlays/Error'
import SummaryContainer from '../../summary/SummaryContainer'

class DibsContainer extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        const { dibs, actions, history, state } = this.props;
        const querystrings = getParams();

        //new dibs transaction
        if (!querystrings.cancel && !querystrings.orderid && !_.isEmpty(dibs.parameters) && document.dibsform) {
            sessionStorage.setItem('state', JSON.stringify(state));
            document.dibsform.submit();
        }
        // cancel dibs transaction
        else if (querystrings.cancel) {
            history.go(-3); //To be revised...nav in dibs window fucks stuff up...
        }
        // complete dibs transaction
        else if (querystrings.orderid && querystrings.transact) {
            actions.completeCheckout(querystrings.orderid, querystrings.transact);
        }
    }

    componentDidUpdate() {
        const { checkoutComplete, history, organizerId, salesChannel } = this.props;
        if (checkoutComplete) {
            const channel = salesChannel ? `&saleschannel=${salesChannel}` : '';
            sessionStorage.removeItem('state');
            history.push(`${ROUTES.RECEIPT}?org=${organizerId}${channel}`);
        }
    }

    generateInputs(dibs) {
        const { organizerId } = this.props;
        let inputs = _.map(dibs.parameters, function (value, key) { return <input key={key} type="hidden" name={key} value={value ? value : ''} /> });
        inputs.push(<input type="hidden" key="dibsaccepturl" name="accepturl" value={`${window.location.origin}/dibs?org=${organizerId}`} />);
        inputs.push(<input type="hidden" key="dibscancelurl" name="cancelurl" value={`${window.location.origin}/dibs?cancel=true&org=${organizerId}`} />);
        inputs.push(<input type="hidden" key="fullreply" name="fullreply" value="yes" />);
        return inputs;
    }

    render() {
        const { appLocale, dibs, error, organizerId, salesChannel } = this.props;
        const inputs = this.generateInputs(dibs);
        const form = dibs.url && inputs ? <form name="dibsform" method="post" action={dibs.url}>{inputs}</form> : null;
        const serverError = error ? <Error error={error} buttons={<input type="button" value={LOCALE[appLocale].startOver} onClick={() => window.location.href = `/?org=${organizerId}${salesChannel}`} />} /> : null;

        return (
            <div className="DibsContainer">
                {serverError}
                {form}

                <SummaryContainer />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    if (_.isEmpty(state.checkout.dibs.parameters)) ownProps.history.replace('/');

    return {
        appLocale: state.app.locale,
        organizerId: state.webshop.organizer.id,
        salesChannel: state.webshop.salesChannel ? `&saleschannel=${state.webshop.salesChannel}` : '',
        dibs: state.checkout.dibs,
        checkoutComplete: state.checkout.completed,
        error: state.checkout.error,
        state //for session storage after dibs payment on seperate page
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ completeCheckout }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DibsContainer)