import {
    PRODUCT_CHANGED,
    AMOUNT_CHANGED,
    QUANTITY_CHANGED,
    ADULT_QUANTITY_CHANGED,
    CHILD_QUANTITY_CHANGED,
    IS_IMAX_CHANGED,
    MESSAGE_CHANGED,
    IS_DIGITAL_CHANGED,
    DESIGN_CHANGED,
    RESET_ORDER,
    TOTALPRICE_CHANGED
} from '../actions/order-actions'

const initialState = {
    product: {},
    quantity: 1,
    adultQuantity: 0,
    childQuantity: 0,
    isImax: false,
    designId: 0,
    message: '',
    isDigital: false,
    availableAsPhysical: false,
    totalPrice: 0
}

export default function order(
    state = initialState, action) {
    switch (action.type) {
        case PRODUCT_CHANGED:
            return {
                ...state,
                product: {
                    ...action.product,
                    price: action.product.price ? action.product.price : action.product.minAmount
                },
                isDigital: action.product.availableAsDigital,
                availableAsPhysical: action.product.availableAsPhysical,
                quantity: action.product.minQuantity || 1,
                incrementBy: action.product.incrementBy || 1,
                isImax: false,
                adultQuantity: 0,
                childQuantity: 0,
                designId: 0,
                totalPrice: action.product.price ? action.product.price : action.product.minAmount
            }
        case AMOUNT_CHANGED:
            return {
                ...state,
                product: {
                    ...state.product,
                    price: action.amount
                }
            }
        case QUANTITY_CHANGED:
            return {
                ...state,
                quantity: action.quantity,
                totalPrice: action.totalPrice
            }
        case ADULT_QUANTITY_CHANGED: {
            return {
                ...state,
                adultQuantity: action.adultQuantity
            }
        }
        case CHILD_QUANTITY_CHANGED: {
            return {
                ...state,
                childQuantity: action.childQuantity
            }
        }
        case IS_IMAX_CHANGED: {
            return {
                ...state,
                isImax: action.isImax
            }
        }
        case TOTALPRICE_CHANGED: {
            return {
                ...state,
                totalPrice: action.totalPrice
            }
        }
        case MESSAGE_CHANGED: {
            return {
                ...state,
                message: action.message
            }
        }
        case IS_DIGITAL_CHANGED: {
            return {
                ...state,
                isDigital: action.isDigital
            }
        }
        case DESIGN_CHANGED:
            return {
                ...state,
                designId: action.designId
            }
        case RESET_ORDER:
            return initialState

        default:
            return state
    }
}