import React from 'react'

export default class DesignListItem extends React.Component {

    render() {
        const { design, designClicked } = this.props;
        return (
            <div className="DesignListItem">
                <img id={design.id} onClick={designClicked} src={design.image} alt={'billede af: ' + design.name} />
                <h3>{design.name}</h3>
            </div>
        )
    }
}