import React from 'react'
import LOCALE from '../AppLocale'

export default class Summary extends React.Component {

    render() {
        const { order, totalPrice, designName, appLocale, transactionFee } = this.props;
        const design = designName ? ' (' + designName + ')' : null;
        const fee = transactionFee > 0 ? `, ${LOCALE[appLocale].fee} ${transactionFee} ${LOCALE[appLocale].currency}` : null;
        return (
            <div className="Summary">
                <div className="summary-container">
                    <h3>{LOCALE[appLocale].yourOrder}</h3>
                    <div className="summary-info">
                        <div>{order.quantity} x {order.product.name}{design}{fee}</div>
                        <div className="total-price">{LOCALE[appLocale].totalPrice}: {totalPrice % 1 != 0 ? totalPrice.toFixed(2).replace('.', ',') : totalPrice} {LOCALE[appLocale].currency}</div>
                    </div>
                </div>
            </div>
        )
    }
}
