export function isiPhone() {
    return navigator.userAgent.match(/iPhone/i) != null;
}

export function getParams(url) {
    let params = {};
    url = url ? url : window.location.href;
    const match = url.match(/\?(.*)$/);

    if (match && match[1]) {
        match[1].split('&').forEach((pair) => {
            pair = pair.split('=');
            params[pair[0]] = pair[1];
        });
    }
    return params;
}