export const AVAILABLE_LOCALES = [
    { id: 'da', name: 'Dansk', flag: '/images/flags/DK.png' },
    { id: 'en', name: 'English', flag: '/images/flags/UK.png' }
];

export const SITE_TYPES = {
    WEB: 0,
    APP: 1,
    UMBRACO: 2
}
