import fetch from 'isomorphic-fetch'
import _ from 'lodash'
import { SITE_TYPES } from './constants'
import moment from 'moment'

export default {
    fetchWebshop,
    checkout,
    completeCheckout
}

export const baseUrl = window.location.href.indexOf('localhost') != -1 ? 'http://localhost:51666/' : window.location.protocol + '//' + window.location.host + '/api/';
export const organizerUrls = {
    'localhost:3000': '3',
    'dev.tickets.dk': '3',
    'gavebillet.dk': '160',
    'www.gavebillet.dk': '160'
}

const organizersWithCustomStyles = ['160', '193', '128', '159', '163', '230']; //strings because we get the id from the querystring
function checkAndLoadCustomStyles(organizerId, siteType) {
    if (siteType == SITE_TYPES.UMBRACO) {
        const path = '/css/umbraco.css';
        let umbracoOverrides = document.createElement('link');
        umbracoOverrides.href = path;
        umbracoOverrides.rel = 'stylesheet';
        document.getElementsByTagName('head')[0].appendChild(umbracoOverrides);
    }

    if (_.includes(organizersWithCustomStyles, organizerId)) {
        const path = '/custom-organizer-styles/organizer_' + organizerId + '.css?version=' + moment().format('YYYYMMDDHHmmss');
        let styleOverrides = document.createElement('link');
        styleOverrides.href = path;
        styleOverrides.rel = 'stylesheet';
        document.getElementsByTagName('head')[0].appendChild(styleOverrides);
    }
}

function checkAndLoadFacebookPixel(organizerId) {
    //CinemaxX are the only ones for now
    if (organizerId == 160) {
        const pixelId = 856969834443964;
        var script = document.createElement('script');
        script.innerHTML = `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${pixelId}');
          fbq('track', 'PageView');
        `;

        var noscript = document.createElement('noscript');
        noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1" />`;

        const head = document.getElementsByTagName('head')[0];
        head.appendChild(script);
        head.appendChild(noscript);
    }
}

function fetchWebshop(organizerId, siteType, salesChannel = "") {
    checkAndLoadCustomStyles(organizerId, siteType);
    checkAndLoadFacebookPixel(organizerId);
    return fetch(`${baseUrl}webshop?organizerId=${organizerId}&salesChannel=${salesChannel}`).then(response => {
        if (response.ok) return response.json();
        else throw response
    })
}

async function checkout(checkout) {
    return await fetch(baseUrl + 'webshop/checkout', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(checkout)
    }).then(response => {
        if (response.ok) return response.json();
        else throw response
    })
}

function completeCheckout(completeCheckout) {
    return fetch(baseUrl + 'webshop/complete-checkout', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(completeCheckout)
    }).then(response => {
        if (response.ok) return response.json();
        else throw response
    })
}

export function logTrace(method, message, logLevel = 'information') {
    const body = {
        message,
        logLevel,
        origin: 'Frontend',
        method,
        url: window.location.href
    }

    fetch(baseUrl + 'logs', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    });
}