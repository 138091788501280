import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import api from '../../../api/reepay-api'
import { ROUTES } from '../../navigation/route-constants'
import { logTrace } from '../../../api/webshop-api'
import { resetCheckout, completeCheckout } from '../../../actions/checkout-actions'
import { getParams } from '../../../utilities/location-utilities'
import { SITE_TYPES } from '../../../api/constants'
import SummaryContainer from '../../summary/SummaryContainer'

class ReepayContainer extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        const { dibs, customer, actions, sessionState, salesChannel, organizerId, iframeId, siteType, productId, origin } = this.props;
        const querystrings = getParams();
        const channel = salesChannel ? `&saleschannel=${salesChannel}` : '';

        //new reepay transaction
        if (!querystrings.cancel && !querystrings.invoice) {
            sessionStorage.setItem('state', JSON.stringify(sessionState));
            const message = `orgId: ${organizerId}, salesChannel: ${salesChannel}, customer email: ${customer.email}, customer phone: ${customer.phone}`;
            logTrace('ReepayContainer - NEW TRANSACTION', message);
            const params = dibs.parameters;

            const type = siteType ? `&sitetype=${siteType}` : '';
            let callback = window.location.origin + `/reepay?org=${organizerId}${channel}&iframeid=${iframeId}${type}`;
            let acceptUrl = callback + '&accept=true';
            let cancelUrl = callback + '&cancel=true';

            if (siteType == SITE_TYPES.UMBRACO && origin) {
                const base64Url = btoa(callback);
                acceptUrl = `${origin}/reepay-landing-page/?iframeid=${iframeId}&iframeurl=${base64Url}`;
                cancelUrl = `${origin}/reepay-landing-page/?cancel=true&iframeid=${iframeId}&iframeurl=${base64Url}`;
            }

            const reepayObject = {
                order: {
                    handle: params.orderid,
                    customer: {
                        first_name: customer.name,
                        phone: customer.phone,
                        email: customer.email
                    },
                    amount: params.amount,
                    metadata: {
                        productId: productId,
                        salesChannel: salesChannel,
                        organizerId: organizerId,
                        iframeUrl: callback
                    },
                },
                accept_url: acceptUrl,
                cancel_url: cancelUrl
            };

            logTrace('REEPAY - SESSION ID REQUEST', JSON.stringify(reepayObject));
            api.fetchSessionId(btoa(params.merchant), reepayObject).then((session) => {
                logTrace('REEPAY - SESSION ID SUCCESS', session.id);
                if (window.parent != window) {
                    console.log('[CHILD] Start Reepay! Posting to parent: ');
                    console.log({ iframeId, sessionId: session.id });

                    window.parent.postMessage({
                        iframeId: iframeId,
                        reepay: {
                            sessionId: session.id
                        }
                    }, '*');
                } else {
                    new window.Reepay.WindowCheckout(session.id);
                }
            });
        }

        // cancel reepay transaction
        if (querystrings.cancel) {
            const { history } = this.props;
            const message = `Reepay sessionId: ${querystrings.id}, Reepay invoiceId: ${querystrings.invoice} orgId: ${organizerId}, salesChannel: ${salesChannel}, customer email: ${customer.email}, customer phone: ${customer.phone}`;
            logTrace('ReepayContainer - CANCEL TRANSACTION', message);
            sessionStorage.removeItem('state');
            actions.resetCheckout();
            history.push(`${ROUTES.HOME}?org=${organizerId}${channel}`);
        }

        // complete reepay transaction
        if (!querystrings.cancel && querystrings.invoice) {
            const message = `orderId/Reepay invoiceId: ${querystrings.invoice}, reepay sessionId: ${querystrings.id}, orgId: ${organizerId}, salesChannel: ${salesChannel}, customer email: ${customer.email}, customer phone: ${customer.phone}`;
            logTrace('ReepayContainer - COMPLETING TRANSACTION', message);
            actions.completeCheckout(querystrings.invoice, querystrings.id);
        }
    }

    componentDidUpdate() {
        const { checkoutComplete, history, organizerId, salesChannel } = this.props;
        if (checkoutComplete) {
            const channel = salesChannel ? `&saleschannel=${salesChannel}` : '';
            sessionStorage.removeItem('state');
            history.push(`${ROUTES.RECEIPT}?org=${organizerId}${channel}`);
        }
    }

    render() {
        const { error } = this.props;

        return (
            <div className="ReepayContainer">
                <div className="error">{error}</div>
                <SummaryContainer />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        appLocale: state.app.locale,
        iframeId: state.app.iframeId,
        origin: state.app.origin,
        organizerId: state.webshop.organizer.id,
        salesChannel: state.webshop.salesChannel ? `&saleschannel=${state.webshop.salesChannel}` : '',
        siteType: state.webshop.siteType,
        productId: state.order.productId,
        dibs: state.checkout.dibs,
        customer: state.checkout.customer,
        checkoutComplete: state.checkout.completed,
        error: state.checkout.error,
        sessionState: state //for session storage after reepay payment on seperate page
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ resetCheckout, completeCheckout }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReepayContainer)