import './product.scss'

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { appHeightChanged } from '../../../actions/app-actions'
import LOCALE from '../../AppLocale'
import { ROUTES } from '../../navigation/route-constants'
import { CATEGORIES } from '../product-constants'
import * as orderActions from '../../../actions/order-actions'
import Product from './Product'
import SummaryContainer from '../../summary/SummaryContainer'


class ProductContainer extends React.Component {
    constructor() {
        super();
        this.state = { error: '' };
        this.quantityChanged = this.quantityChanged.bind(this);
        this.amountChanged = this.amountChanged.bind(this);
        this.adultQuantityChanged = this.adultQuantityChanged.bind(this);
        this.childQuantityChanged = this.childQuantityChanged.bind(this);
        this.isImaxChanged = this.isImaxChanged.bind(this);
        this.messageChanged = this.messageChanged.bind(this);
        this.isDigitalChanged = this.isDigitalChanged.bind(this);
        this.navBack = this.navBack.bind(this);
        this.navNext = this.navNext.bind(this);
    }

    componentDidMount() {
        const { order, product, actions, iframeId } = this.props;

        if (window.parent != window) actions.appHeightChanged(iframeId);
        else window.scrollTo(0, 0);

        if (!order.product.id || order.product.id != product.id) actions.productChanged(product);
    }

    componentWillReceiveProps(nextProps) {
        const { order, actions } = this.props;
        if (nextProps.product.id !== order.product.id) actions.productChanged(nextProps.product);
    }

    quantityChanged(quantity) {
        this.setState({ error: '' });
        const { actions } = this.props;
        actions.quantityChanged(quantity);
    }

    amountChanged(amount) {
        this.setState({ error: '' });
        const { actions } = this.props;
        actions.amountChanged(amount);
    }

    adultQuantityChanged(adultQuantity) {
        this.setState({ error: '' });
        const { actions } = this.props;
        actions.adultQuantityChanged(adultQuantity);
    }

    childQuantityChanged(childQuantity) {
        this.setState({ error: '' });
        const { actions } = this.props;
        actions.childQuantityChanged(childQuantity);
    }

    isImaxChanged(e) {
        const isImax = e.target.checked;
        const { actions } = this.props;
        actions.isImaxChanged(isImax);
    }

    messageChanged(message) {
        const { actions } = this.props;
        actions.messageChanged(message);
    }

    isDigitalChanged(e) {
        const isDigital = !e.target.checked;
        const { actions } = this.props;
        actions.isDigitalChanged(isDigital);
    }

    navBack() {
        const { order, history, organizerId, salesChannel } = this.props;
        const channel = salesChannel ? `&saleschannel=${salesChannel}` : '';
        if (order.product.category !== CATEGORIES.GIFTBOX) history.push(`${ROUTES.HOME}?org=${organizerId}${channel}`);
        else history.goBack();
    }

    navNext() {
        const { order, appLocale } = this.props;
        if (order.quantity > 0) {
            switch (order.product.category) {
                case CATEGORIES.GIFTBOX:
                case CATEGORIES.DONATIONS:
                    this.goNext(order.isDigital);
                    break;
                case CATEGORIES.GIFTCARD:
                    if (order.product.price >= order.product.minAmount) this.goToCheckout();
                    else this.setState({ error: LOCALE[appLocale].errorMinAmount.replace('INSERT_MIN_AMOUNT', order.product.minAmount) });
                    break;
                case CATEGORIES.COMBOBOX:
                    if (order.adultQuantity > 0 || order.childQuantity > 0) this.goNext(order.isDigital);
                    else this.setState({ error: LOCALE[appLocale].errorMinTicket });
                    break;
            }
        } else this.setState({ error: LOCALE[appLocale].errorMinDefault });
    }

    goNext(isDigital) {
        if (isDigital) this.goToCheckout();
        else this.chooseDesign();
    }

    goToCheckout() {
        const { organizerId, history, salesChannel } = this.props;
        const channel = salesChannel ? `&saleschannel=${salesChannel}` : '';
        history.push(`${ROUTES.CHECKOUT}?org=${organizerId}${channel}`);
    }

    chooseDesign() {
        const { organizerId, history, salesChannel } = this.props;
        const channel = salesChannel ? `&saleschannel=${salesChannel}` : '';
        history.push(`${ROUTES.DESIGN}?org=${organizerId}${channel}`);
    }

    render() {
        const { isLandingPage, order, fees, history, organizerId, includeMessage, appLocale, salesChannel } = this.props;
        const channel = salesChannel ? `&saleschannel=${salesChannel}` : '';
        const error = this.state.error ? <div className="nav-error">{this.state.error}</div> : null;
        return (
            <div className="ProductContainer">
                <Product appLocale={appLocale}
                    order={order}
                    fees={fees}
                    isDigitalChanged={this.isDigitalChanged}
                    quantityChanged={this.quantityChanged}
                    amountChanged={this.amountChanged}
                    adultQuantityChanged={this.adultQuantityChanged}
                    childQuantityChanged={this.childQuantityChanged}
                    isImaxChanged={this.isImaxChanged}
                    includeMessage={includeMessage}
                    messageChanged={this.messageChanged}
                    organizerId={organizerId}
                />
                {error}
                <div className="nav-buttons">
                    {!isLandingPage ? <input type="button" className="back" onClick={() => history.push(`${ROUTES.HOME}?org=${organizerId}${channel}`)} value={LOCALE[appLocale].back} /> : null}
                    <input type="button" className="next" onClick={this.navNext} value={LOCALE[appLocale].next} />
                </div>

                <SummaryContainer appLocale={appLocale} />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const product = _.find(state.webshop.products, (product) => { return product.id == ownProps.match.params.id });
    const organizerId = state.webshop.organizer.id;

    return {
        appLocale: state.app.locale,
        organizerId,
        product,
        order: state.order,
        fees: state.webshop.fees,
        isLandingPage: state.webshop.products.length == 1,
        includeMessage: !state.order.isDigital,
        salesChannel: state.webshop.salesChannel
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...orderActions, appHeightChanged }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer)