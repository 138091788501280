import './designlist.scss'

import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import LOCALE from '../AppLocale'
import { designChanged } from '../../actions/order-actions'
import { ROUTES } from '../navigation/route-constants'
import DesignList from './DesignList'
import SummaryContainer from '../summary/SummaryContainer'

class DesignListContainer extends React.Component {
    constructor() {
        super();
        this.designClicked = this.designClicked.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    designClicked(e) {
        const newDesign = e.target.id;
        const { actions, history, organizerId, salesChannel } = this.props;
        const channel = salesChannel ? `&saleschannel=${salesChannel}` : '';
        actions.designChanged(newDesign);
        history.push(`${ROUTES.CHECKOUT}?org=${organizerId}${channel}`);
    }

    render() {
        const { appLocale, designs, history } = this.props;
        return (
            <div className="DesignListContainer">
                <DesignList
                    designs={designs}
                    designClicked={this.designClicked}
                />

                <div className="nav-buttons">
                    <input type="button" className="back" onClick={() => { history.goBack(); window.scrollTo(0, 0); }} value={LOCALE[appLocale].back} />
                </div>

                <SummaryContainer />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    if (_.isEmpty(state.order.product)) ownProps.history.replace('/');
    const designs = state.order.product.id !== 6 ? _.reject(state.webshop.designs, (design) => { return design.id === 11 }) : state.webshop.designs; //quickfix for now...

    return {
        appLocale: state.app.locale,
        organizerId: state.webshop.organizer.id,
        designs,
        salesChannel: state.webshop.salesChannel
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ designChanged }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DesignListContainer)