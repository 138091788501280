import './mainmenu.scss'

import React from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import _ from 'lodash'
import { ROUTES } from '../route-constants'

class MainMenu extends React.Component {
    constructor() {
        super();
        this.state = { burgerMenuOpen: false }
        this.toggleBurgerMenu = this.toggleBurgerMenu.bind(this);
        this.closeburgerMenu = this.closeburgerMenu.bind(this);
    }

    toggleBurgerMenu(e) {
        if (window.outerWidth < 600) {
            const newMenuState = this.state.burgerMenuOpen ? false : true;
            this.setState({ burgerMenuOpen: newMenuState })
        }
    }

    closeburgerMenu() {
        this.setState({ burgerMenuOpen: false })
    }

    generateMenu() {
        const { menu } = this.props;
        return menu.map((item) => {
            const navLink = _.startsWith(item.navigateTo, '/') ?
                <NavLink onClick={this.toggleBurgerMenu} to={item.navigateTo}>{item.name}</NavLink> :
                <a href={item.navigateTo} target="_blank">{item.name}</a>;

            return <li key={item.id}>{navLink}</li>
        });
    }

    render() {
        const { logo, organizer } = this.props;
        const generatedMenuItems = this.generateMenu();
        const logoHeader = logo ? <img onClick={this.closeburgerMenu} src={logo} /> : <h1>{organizer.name}</h1>;

        return (
            <nav>
                <NavLink exact className={this.state.burgerMenuOpen ? 'open burger-menu-logo' : 'burger-menu-logo'} to={`${ROUTES.HOME}?org=${organizer.id}`}>{logoHeader}</NavLink>
                <div id="burger-menu" className={this.state.burgerMenuOpen ? 'open' : ''} onClick={this.toggleBurgerMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <ul className={!this.state.burgerMenuOpen ? 'burger-menu-closed' : ''}>
                    <li className="logo"><NavLink exact to={`${ROUTES.HOME}?org=${organizer.id}`}>{logoHeader}</NavLink></li>
                    {generatedMenuItems}
                </ul>
            </nav>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        logo: state.webshop.logo,
        menu: state.webshop.menu,
        organizer: state.webshop.organizer
    }
}

export default withRouter(connect(mapStateToProps)(MainMenu))