import React from 'react'
import _ from 'lodash'
import LOCALE from '../../AppLocale'
import QuantitySelector from './QuantitySelector'
import Amount from './Amount'


export default class GiftCard extends React.Component {

    render() {
        const { appLocale, order, fees, isDigitalChanged, quantityChanged, amountChanged } = this.props;
        const product = order.product;
        const minMax = product.minAmount && product.maxAmount ? <span>({product.minAmount} - {product.maxAmount} {LOCALE[appLocale].currency})</span> : null;

        let digital = product.availableAsPhysical ? <div className="quantity-container digital"><label>
            {product.availableAsPhysical && product.availableAsDigital ? <input type="checkbox" checked={!order.isDigital} onChange={isDigitalChanged} /> : null}
            {digital} {LOCALE[appLocale].giftCardWithShipping} <span className="shippingCostText">{fees.shipping} {LOCALE[appLocale].currency}</span></label></div> : null;

        return (
            <div className="GiftCard quantity-selectors">
                <div className="quantity-container">
                    <h4>{LOCALE[appLocale].quantity}</h4>
                    <QuantitySelector quantity={order.quantity}
                        quantityChanged={quantityChanged}
                        minQuantity={product.minQuantity}
                        maxQuantity={product.maxQuantity}
                        incrementBy={product.incrementBy} />

                </div>

                <div className="quantity-container amount-container">
                    <h4>{LOCALE[appLocale].amount} {minMax} </h4>
                    <Amount appLocale={appLocale}
                        amount={product.price}
                        amountChanged={amountChanged}
                        maxAmount={product.maxAmount} />
                </div>

                {digital}
            </div>
        )
    }
}