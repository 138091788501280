import 'react-app-polyfill/ie9';

import React from 'react'
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux'
import { render } from 'react-dom'
import './components/overlays/overlays.scss'

import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './components/App'
import rootReducer from './reducers/root-reducer'
import { logTrace } from './api/webshop-api'
import localStorageUtils from './utilities/localstorage-utilities'
import getWebshop from './actions/webshop-actions'
import Error from './components/overlays/Error'
import Spinner from './components/overlays/Spinner'
import { getParams } from './utilities/location-utilities'
import { organizerUrls } from './api/webshop-api'
import { languageChanged } from './actions/app-actions'

const dibsState = JSON.parse(sessionStorage.getItem('state'));
const persistedCheckout = localStorageUtils.getItem('checkout');
const persistedState = dibsState ? dibsState : persistedCheckout ? { checkout: persistedCheckout } : undefined;

const queryParameters = getParams(window.location.href);
const organizerId = queryParameters.org || queryParameters.organizer || organizerUrls[window.location.host];
const siteType = queryParameters.sitetype;
const locale = queryParameters.lang;
const salesChannel = dibsState ? dibsState.webshop.salesChannel : queryParameters.saleschannel || queryParameters.SalesChannel;

if (dibsState) {
    const customer = dibsState.customer && dibsState.checkout.customer ? dibsState.checkout.customer : null;
    const organizer = dibsState.webshop && dibsState.webshop.organizer ? dibsState.webshop.organizer: null;
    const message = `FROM SESSION STATE: orgId: ${organizer ? organizer.id : null}, customer email: ${customer ? customer.email : null}, customer phone: ${customer ? customer.phone : null}, FROM QUERY PARAMS: ${JSON.stringify(queryParameters)}`;
    logTrace('index.js - STARTUP AFTER REEPAY/DIBS', message);
}

//REDUX DEV TOOLS
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(thunk)));

render(<Spinner store={store} show={true} />, document.getElementById('root'));
if (organizerId) {
    store.dispatch(getWebshop(organizerId, siteType, salesChannel)).then((result) => {
        store.dispatch(languageChanged(locale));
        if (!result.error) {
            render(
                <Provider store={store}>
                    <Router>
                        <App />
                    </Router>
                </Provider >,
                document.getElementById('root')
            )
        } else render(<Error error={result.error} />, document.getElementById('root'));
    });
}