import './pages.scss'

import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

class Contact extends React.Component {

    render() {
        const { content } = this.props;
        return (
            <div className="Contact">
                <h1>Kontakt</h1>
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const page = _.find(state.webshop.pages, (page) => { return page.path == ownProps.location.pathname });
    return {
        content: page ? page.content : null
    }
}

export default connect(mapStateToProps)(Contact)