export const ROUTES = {
    HOME: '/',
    PRODUCTS: '/produkter',
    DESIGN: '/design',
    CHECKOUT: '/kunde-oplysninger',
    DIBS: '/dibs',
    REEPAY: '/reepay',
    RECEIPT: '/kvittering',
    FAQ: '/faq',
    CONTACT: '/kontakt',
    
    GIFTBOXES: 'gavebokse'    // filter
}