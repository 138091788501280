import React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../navigation/route-constants'

export default class ProductListItem extends React.Component {

    constructor() {
        super();
        this.productClicked = this.productClicked.bind(this);
    }

    productClicked(e) {
        const { product, productClicked } = this.props;
        productClicked(product.id);
    }
    
    render() {
        const { product } = this.props;
        return (
            <div className="ProductListItem">
                <img onClick={this.productClicked} src={product.image} alt={'Billede af: ' + product.name} />
            </div>
        )
    }
}