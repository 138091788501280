import fetch from 'isomorphic-fetch'
import _ from 'lodash'

export default {
    fetchSessionId
}

async function fetchSessionId(apiKey, reepayObject) {
    return await fetch('https://checkout-api.reepay.com/v1/session/charge', {
        method: 'POST',
        headers: {
            'Authorization': `Basic ${apiKey}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(reepayObject)
    }).then(response => {
        if (response.ok) return response.json();
        else throw response
    });
}