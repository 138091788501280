import React from 'react'
import VOUCHER_NAMES from './receipt-constants'

export default class Voucher extends React.Component {

    getBackgroundImageTicketType(ticketType) {
        switch (ticketType) {
            case VOUCHER_NAMES.GAVEKORT:
                return '/images/cinemaxx/CinemaxX_E-Billet_Webshop_Biografbillet_190x67_03_Gavekort.jpg';
            case VOUCHER_NAMES.GAVEBILLET:
            case VOUCHER_NAMES.GAVEBILLET_BARN:
            case VOUCHER_NAMES.GAVEBILLET_LUKSUS:
                return '/images/cinemaxx/CinemaxX_E-Billet_Webshop_Biografbillet_190x67_01_Biografbillet.jpg';
            case VOUCHER_NAMES.GAVEBILLET_IMAX:
                return '/images/cinemaxx/CinemaxX_E-Billet_Webshop_Biografbillet_190x67_05_IMAX.jpg';
            case VOUCHER_NAMES.GAVEBILLET_IMAX_BARN:
                return '/images/cinemaxx/CinemaxX_E-Billet_Webshop_Biografbillet_190x67_08_IMAX_Børnebillet.jpg';
            case VOUCHER_NAMES.GAVE_MENU:
                return '/images/cinemaxx/CinemaxX_E-Billet_Webshop_Biografbillet_190x67_04_1_Biografmenu.jpg';
            case VOUCHER_NAMES.GAVE_MENU_BARN:
                return '/images/cinemaxx/CinemaxX_E-Billet_Webshop_Biografbillet_190x67_07_Børnemenu.jpg';
        }
    }

    render() {
        const { index, voucher, showPrice } = this.props;
        const price = showPrice ? <label className="price">KR. {voucher.price}.-</label> : null;
        const backgroundImage = this.getBackgroundImageTicketType(voucher.name);

        return (
            <div className={index === 1 ? 'Voucher break-after' : 'Voucher'}>
                <img className="background" src={backgroundImage} />
                <div className="info">
                    <h2>{voucher.name}</h2>
                    {price}
                    <p dangerouslySetInnerHTML={{ __html: voucher.description }}></p>
                </div>
                <p className="qr-code">
                    <img src={'http://www.ebillet.dk/system/barcode.asmx/QRBarCode?text=' + voucher.barCode} />
                    {voucher.barCode}
                    <div>Gyldig til og med {voucher.expires}</div>
                </p>
            </div>
        )
    }
}