import { combineReducers } from 'redux'
import app from './app-reducer'
import webshop from './webshop-reducer'
import order from './order-reducer'
import checkout from './checkout-reducer'

export default combineReducers({
    app,
    webshop,
    order,
    checkout
})