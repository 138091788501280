import './checkout.scss'

import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import * as checkoutActions from '../../actions/checkout-actions'
import { appHeightChanged } from '../../actions/app-actions'
import LOCALE from '../AppLocale'
import Customer from './Customer'
import Shipping from './Shipping'
import Payment from './payment/Payment'
import Error from '../overlays/Error'
import SummaryContainer from '../summary/SummaryContainer'
import { ROUTES } from '../navigation/route-constants'
import { CATEGORIES } from '../products/product-constants'

class CheckOutContainer extends React.Component {
    constructor() {
        super();
        this.state = { error: '' };
        this.navNext = this.navNext.bind(this);
        window.scrollTo(0, 0);
        sessionStorage.removeItem('state')
    }

    componentDidMount() {
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId);
        else window.scrollTo(0, 0);
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.checkoutIsValid && nextProps.checkoutIsValid) this.setState({ error: '' });
    }

    async navNext() {
        const { order, checkoutIsValid, history, actions, webshop, appLocale, payWithReepay } = this.props;
        if (order.product.id && checkoutIsValid && await actions.checkout() === true)
            payWithReepay ? history.push(`${ROUTES.REEPAY}?org=${webshop.organizer.id}`) : history.push(`${ROUTES.DIBS}?org=${webshop.organizer.id}`);
        else if (order.product.id && !checkoutIsValid) this.setState({ error: LOCALE[appLocale].errorRequiredInputs });
        else this.setState({ error: LOCALE[appLocale].errorMinProduct });
    }

    render() {
        const { checkout, order, webshop, history, actions, error, appLocale, salesChannel, payWithReepay } = this.props;
        const shipping = !order.isDigital ? <Shipping appLocale={appLocale} shipping={checkout.shipping} rememberMe={checkout.rememberMe} actions={actions} /> : null;
        const totalPrice = order.isDigital ? order.totalPrice : order.totalPrice + (order.product.category == CATEGORIES.GIFTCARD ? webshop.fees.shipping : webshop.fees.shipping + (webshop.fees.handling * order.quantity));
        const navError = this.state.error ? <div className="nav-error">{this.state.error}</div> : null;
        const serverError = error ? <Error error={error} buttons={<input type="button" value={LOCALE[appLocale].startOver} onClick={() => window.location.href = `/?org=${webshop.organizer.id}${salesChannel}`} />} /> : null;

        return (
            <div className="CheckOutContainer">
                {serverError}
                <Customer appLocale={appLocale}
                    organizer={webshop.organizer}
                    customer={checkout.customer}
                    rememberMe={checkout.rememberMe}
                    actions={actions}
                    payment={checkout.payment}
                    payWithReepay={payWithReepay}
                />

                {shipping}

                {!payWithReepay ? <Payment appLocale={appLocale}
                    payment={checkout.payment}
                    creditCards={webshop.creditCards}
                    totalPrice={totalPrice}
                    actions={actions}
                    organizer={webshop.organizer}
                /> : null}

                {navError}
                <div className="nav-buttons">
                    <input type="button" className="back" onClick={() => history.goBack()} value={LOCALE[appLocale].back} />
                    <input type="button" className="next" onClick={this.navNext} value={LOCALE[appLocale].next} />
                </div>

                <SummaryContainer />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    if (_.isEmpty(state.order.product)) ownProps.history.replace('/');
    const payWithReepay = _.startsWith(state.webshop.organizer.dibs.merchantId, 'priv_');

    return {
        appLocale: state.app.locale,
        order: state.order,
        checkout: state.checkout,
        webshop: state.webshop,
        payWithReepay,
        salesChannel: state.webshop.salesChannel ? `&saleschannel=${state.webshop.salesChannel}` : '',
        checkoutIsValid: state.order.isDigital ? state.checkout.customer.isValid && state.checkout.payment.isValid : state.checkout.customer.isValid && state.checkout.shipping.isValid && state.checkout.payment.isValid,
        error: state.checkout.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...checkoutActions, appHeightChanged }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckOutContainer)
