import React from 'react'
import LOCALE from '../../AppLocale'

export default class Amount extends React.Component {
    constructor(props) {
        super(props);

        this.state = { amount: props.amount };
        this.amountChanged = this.amountChanged.bind(this);
        this.amountBlur = this.amountBlur.bind(this);
    }

    amountChanged(e) {
        let amount = e.target.value;
        const nonNumericRegex = /[^0-9.]+/g;
        amount = amount.replace(nonNumericRegex, '');

        const { maxAmount } = this.props;
        const canChange = maxAmount ? amount <= maxAmount : true;
        if (canChange) this.setState({ amount });
    }

    amountBlur(e) {
        const amount = e.target.value;
        this.props.amountChanged(parseInt(amount));
    }

    render() {
        const { appLocale } = this.props;

        return (
            <div className="Amount">
                {LOCALE[appLocale].amountOf} <input type="tel" value={this.state.amount} onChange={this.amountChanged} onBlur={this.amountBlur} /> {LOCALE[appLocale].currency}
            </div>
        )
    }
}