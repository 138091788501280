import React from 'react'
import _ from 'lodash'
import DesignListItem from './DesignListItem'

export default class DesignList extends React.Component {
    
    generateDesignListItems() {
        const { designs, designClicked } = this.props;
        return _.map(designs, (design) => {
            return <DesignListItem key={design.id}
                design={design}
                designClicked={designClicked}
            />
        });
    }

    render() {
        const designListItems = this.generateDesignListItems();
        return (
            <div className="DesignList">
                <h1>Vælg design på din boks ved at klikke på et billede</h1>
                {designListItems}
            </div>
        )
    }
}