import _ from 'lodash'

export const LANGUAGE_CHANGED = 'LANGUAGE_CHANGED'
export function languageChanged(newLocale) {
    return (dispatch, getState) => {
        const isAvailable = _.find(getState().webshop.availableLocales, { id: newLocale });
        if (isAvailable) {
            return dispatch({
                type: LANGUAGE_CHANGED,
                locale: newLocale,
            });
        }
    }
}

export const INIT_FROM_PARENT_SITE = 'INIT_FROM_PARENT_SITE'
export function initFromParentSite(origin) {
    return (dispatch, getState) => {
        return dispatch({
            type: INIT_FROM_PARENT_SITE,
            origin
        });
    };
}

export const APP_HEIGHT_CHANGED = 'APP_HEIGHT_CHANGED'
export function appHeightChanged(initIframeId) {
    return (dispatch, getState) => {
        if (window.parent != window) {
            let { height, iframeId } = getState().app;
            if (!iframeId) iframeId = initIframeId;

            const app = document.querySelector('.App');
            const newPageHeight = Math.max(app.scrollHeight, app.offsetHeight, app.clientHeight);

            if (iframeId !== null && height != newPageHeight) {
                console.log('[CHILD] AppHeightChanged! Posting to parent: ');
                console.log({ iframeId: iframeId, oldHeight: height, newHeight: newPageHeight });

                window.parent.postMessage({
                    iframeId: iframeId,
                    iframeHeight: newPageHeight
                }, '*');

                return dispatch({
                    type: APP_HEIGHT_CHANGED,
                    iframeId,
                    appHeight: newPageHeight
                });
            }
        }
    }
}