import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { languageChanged } from '../../actions/app-actions'

class LanguagePicker extends React.Component {
    constructor() {
        super();
        this.languageChanged = this.languageChanged.bind(this);
    }

    languageChanged(e) {
        const locale = e.currentTarget.id;
        const { actions } = this.props;
        actions.languageChanged(locale);
    }

    render() {
        const { appLocale, availableLocales } = this.props;
        return (
            <div className="LanguagePicker">
                {_.map(availableLocales, (locale) => {
                    const selected = appLocale == locale.id;
                    if (!selected)
                        return <div className={selected ? 'language selected' : 'language'}
                            key={locale.id} id={locale.id} onClick={this.languageChanged}>
                            <img src={locale.flag} />
                        </div>
                })}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        availableLocales: state.webshop.availableLocales,
        appLocale: state.app.locale
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ languageChanged }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePicker)