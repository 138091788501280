import React from 'react'
import _ from 'lodash'

export default class QuantitySelector extends React.Component {
    constructor() {
        super();
        this.decrement = this.decrement.bind(this);
        this.increment = this.increment.bind(this);
    }

    decrement() {
        const { quantity, minQuantity, incrementBy, quantityChanged } = this.props;
        const newQuantity = quantity - incrementBy;
        if (quantity >= minQuantity) quantityChanged(newQuantity);
    }

    increment() {
        const { quantity, maxQuantity, incrementBy, quantityChanged } = this.props;
        const newQuantity = quantity + incrementBy;
        const canIncrement = maxQuantity ? quantity < maxQuantity : true;
        if (canIncrement) quantityChanged(newQuantity);

    }

    render() {
        const { quantity, minQuantity, maxQuantity } = this.props;
        const decrementClasses = quantity > minQuantity ? 'decrement' : 'decrement deactivated';
        const incrementClasses = maxQuantity ? quantity < maxQuantity ? 'increment' : 'increment deactivated' : 'increment';

        return (
            <div className="QuantitySelector">
                <div className={decrementClasses} onClick={this.decrement}><span className="icon-circle-minus"></span></div>
                <div className="quantity">{quantity}</div>
                <div className={incrementClasses} onClick={this.increment}><span className="icon-circle-plus"></span></div>
            </div>
        )
    }
}