import { CATEGORIES } from '../components/products/product-constants'

export const PRODUCT_CHANGED = 'PRODUCT_CHANGED'
export const AMOUNT_CHANGED = 'AMOUNT_CHANGED'
export const QUANTITY_CHANGED = 'QUANTITY_CHANGED'
export const ADULT_QUANTITY_CHANGED = 'ADULT_QUANTITY_CHANGED'
export const CHILD_QUANTITY_CHANGED = 'CHILD_QUANTITY_CHANGED'
export const IS_IMAX_CHANGED = 'IS_IMAX_CHANGED'
export const MESSAGE_CHANGED = 'MESSAGE_CHANGED'
export const IS_DIGITAL_CHANGED = 'IS_DIGITAL_CHANGED'
export const DESIGN_CHANGED = 'DESIGN_CHANGED'
export const TOTALPRICE_CHANGED = 'TOTALPRICE_CHANGED'

export function productChanged(newProduct) {
    return (dispatch) => {
        dispatch({
            type: PRODUCT_CHANGED,
            product: newProduct
        });
        return dispatch(calculateTotalPrice());
    }
}

export function amountChanged(newAmount) {
    return (dispatch) => {
        dispatch({
            type: AMOUNT_CHANGED,
            amount: newAmount
        });
        return dispatch(calculateTotalPrice());
    }
}

export function quantityChanged(newQuantity) {
    return (dispatch) => {

        dispatch({
            type: QUANTITY_CHANGED,
            quantity: newQuantity
        });

        return dispatch(calculateTotalPrice());
    };
}

export function adultQuantityChanged(newAdultQuantity) {
    return (dispatch) => {
        dispatch({
            type: ADULT_QUANTITY_CHANGED,
            adultQuantity: newAdultQuantity
        });
        return dispatch(calculateTotalPrice());
    }
}

export function childQuantityChanged(newChildQuantity) {
    return (dispatch) => {
        dispatch({
            type: CHILD_QUANTITY_CHANGED,
            childQuantity: newChildQuantity
        });
        return dispatch(calculateTotalPrice());
    }
}

export function isImaxChanged(newIsImax) {
    return (dispatch) => {
        dispatch({
            type: IS_IMAX_CHANGED,
            isImax: newIsImax
        });
        return dispatch(calculateTotalPrice());
    }
}

export const messageChanged = (newMessage) => {
    return {
        type: MESSAGE_CHANGED,
        message: newMessage
    }
}

export function isDigitalChanged(newIsDigital) {
    return (dispatch) => {
        dispatch({
            type: IS_DIGITAL_CHANGED,
            isDigital: newIsDigital
        });

        return dispatch(calculateTotalPrice());
    }
}

export function calculateTotalPrice() {
    return (dispatch, getState) => {
        const order = getState().order;
        const fees = getState().webshop.fees;
        let totalPrice = (order.product.price * order.quantity) + fees.transaction;

        if (order.product.category == CATEGORIES.COMBOBOX) {
            totalPrice = totalPrice + (order.isImax === true ? (order.product.priceImaxAdult * order.adultQuantity) + (order.product.priceImaxChild * order.childQuantity) :
                (order.product.priceAdult * order.adultQuantity) + (order.product.priceChild * order.childQuantity))
        }

        if (!order.isDigital)
            totalPrice = order.product.category == CATEGORIES.GIFTCARD ? totalPrice + fees.shipping : totalPrice + fees.shipping + (fees.handling * order.quantity);

        return dispatch({
            type: TOTALPRICE_CHANGED,
            totalPrice
        });
    }
}

export const designChanged = (newDesign) => {
    return {
        type: DESIGN_CHANGED,
        designId: newDesign
    }
}

// RESET
export const RESET_ORDER = 'RESET_ORDER';
export const resetOrder = () => {
    return {
        type: RESET_ORDER,
    }
}