import './productlist.scss'

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { appHeightChanged } from '../../../actions/app-actions'
import ProductListItem from './ProductListItem'
import { ROUTES } from '../../navigation/route-constants'
import { productChanged } from '../../../actions/order-actions'
import { CATEGORIES } from '../product-constants'

class ProductListContainer extends React.Component {
    componentWillMount() {
        const { products, history, organizerId, salesChannel } = this.props;
        const channel = salesChannel ? `&saleschannel=${salesChannel}` : '';
        if (products.length == 1) history.replace(`${ROUTES.PRODUCTS}/${products[0].id}?org=${organizerId}${channel}`);
    }

    constructor() {
        super();
        this.productClicked = this.productClicked.bind(this);
    }

    componentDidMount() {
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId);
        else window.scrollTo(0, 0);
    }

    productClicked(id) {
        const { products, actions, history, organizerId, salesChannel } = this.props;
        const product = _.find(products, (product) => { return product.id == id });
        product.quantity = 1;
        actions.productChanged(product);
        const channel = salesChannel ? `&saleschannel=${salesChannel}` : '';
        history.push(`${ROUTES.PRODUCTS}/${product.id}?org=${organizerId}${channel}`);
    }

    generateProductListItems() {
        const { products } = this.props;
        return _.map(products, (product) => {
            return <ProductListItem key={product.id}
                product={product}
                productClicked={this.productClicked}
            />
        });
    }

    render() {
        const productListItems = this.generateProductListItems();

        return (
            <div className="ProductList">
                {productListItems.length > 0 ? productListItems : <h3 className="no-products">Der var ingen produkter</h3>}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const products = state.webshop.products;
    const filter = ownProps.match.params.filter == ROUTES.GIFTBOXES ? 1 : null;
    const filteredProducts = filter ? _.filter(products, (product) => { return product.category === filter || product.category === CATEGORIES.COMBOBOX }) : products;
    return {
        products: filteredProducts,
        organizerId: state.webshop.organizer.id,
        salesChannel: state.webshop.salesChannel,
        iframeId: state.app.iframeId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ productChanged, appHeightChanged }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListContainer)