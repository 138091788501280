import './app.scss'
import '../styles/print.scss'

import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter, Switch, Route } from 'react-router-dom'
import { getParams } from '../utilities/location-utilities'
import { appHeightChanged, initFromParentSite } from '../actions/app-actions'
import MainMenu from './navigation/mainmenu/MainMenu'
import ProductListContainer from './products/list/ProductListContainer'
import ProductContainer from './products/details/ProductContainer'
import DesignListContainer from './design/DesignListContainer'
import CheckOutContainer from './checkout/CheckOutContainer'
import DibsContainer from './checkout/payment/DibsContainer'
import ReepayContainer from './checkout/payment/ReepayContainer'
import ReceiptContainer from './receipt/ReceiptContainer'
import FAQ from './pages/Faq'
import Contact from './pages/Contact'
import { ROUTES } from './navigation/route-constants'
import Spinner from './overlays/Spinner'
import LanguagePicker from './languagepicker/LanguagePicker'

class App extends React.Component {
    constructor() {
        super();
        const queryParameters = getParams();
        this.state = {
            iframeId: queryParameters.iframeid,
            origin: queryParameters.origin
        }
        this.checkIfAppHeightChanged = this.checkIfAppHeightChanged.bind(this);
        if (window.parent != window) console.log('App.js INIT APP, iframeId: ' + queryParameters.iframeid);
    }

    componentDidMount() {
        // onLoad isn't triggered on <App in RECEIPT after payment, so we put an extra height check after mount...
        if (window.parent != window) {
            const { iframeId, origin } = this.state;
            const { actions } = this.props;
            actions.initFromParentSite(origin); //TODO put iframeId here and maybe also first app height change instead of using onLoad?
            actions.appHeightChanged(iframeId);
        }
        else window.scrollTo(0, 0);
    }

    //This is for handling dynamic height when inside an iframe
    checkIfAppHeightChanged(e) {
        if (window.parent != window) {
            const { iframeId } = this.state;
            const { actions } = this.props;
            actions.appHeightChanged(iframeId);
            console.log('App.js CALLING appHeightChanged(), iframeId: ' + iframeId);
        }
    }

    render() {
        const { availableLocales, showMenu, footer } = this.props;
        const appClasses = showMenu ? 'App full' : 'App';
      
        return (
            <div className={appClasses} onLoad={this.checkIfAppHeightChanged}>
                <Spinner />
                {showMenu ? <MainMenu /> : null}
                {availableLocales.length > 1 ? <LanguagePicker /> : null}
                <div className="content-wrapper">
                    <Switch>
                        <Route path={ROUTES.PRODUCTS + '/:id'} component={ProductContainer} />
                        <Route path={ROUTES.DESIGN} component={DesignListContainer} />
                        <Route path={ROUTES.CHECKOUT} component={CheckOutContainer} />
                        <Route path={ROUTES.DIBS} component={DibsContainer} />
                        <Route path={ROUTES.REEPAY} component={ReepayContainer} />
                        <Route path={ROUTES.RECEIPT} component={ReceiptContainer} />
                        <Route path={ROUTES.FAQ} component={FAQ} />
                        <Route path={ROUTES.CONTACT} component={Contact} />

                        <Route exact path="/:filter?" component={ProductListContainer} />
                    </Switch>
                </div>
                {footer.length ? <footer>
                    <div>
                        <h3>{footer[0].title}</h3>
                        <p dangerouslySetInnerHTML={{ __html: footer[0].content }} ></p>
                    </div>
                </footer>
                    : null}
               
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
   
    return {
        showMenu: state.webshop.menu != null,
        availableLocales: state.webshop.availableLocales,
        footer: state.webshop.footer == null ? '' : state.webshop.footer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { appHeightChanged, initFromParentSite }), dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))