import React from 'react'
import LOCALE from '../../AppLocale'

export default class Message extends React.Component {
    constructor(props) {
        super(props);
        this.state = { message: props.message }
        this.messageChanged = this.messageChanged.bind(this);
    }

    messageChanged(e) {
        const message = e.target.value;
        this.setState({ message });
        this.props.messageChanged(message);
    }

    render() {
        const { appLocale } = this.props;
        const { message } = this.state;

        return (
            <div className="Message">
                <div dangerouslySetInnerHTML={{ __html: LOCALE[appLocale].message }}></div>
                <textarea maxLength="3500" value={message} onChange={this.messageChanged} />
            </div>
        )
    }
}