import React from 'react'
import _ from 'lodash'
import LOCALE from '../../AppLocale'
import QuantitySelector from './QuantitySelector'

export default class Combobox extends React.Component {

    render() {
        const { organizerId, appLocale, order, fees, adultQuantityChanged, childQuantityChanged, isImaxChanged, isDigitalChanged } = this.props;
        const product = order.product;

        const digital = product.availableAsPhysical ? <div className="quantity-container digital"><label>
            {product.availableAsPhysical && product.availableAsDigital ? <input type="checkbox" checked={!order.isDigital} onChange={isDigitalChanged} /> : null}
            {digital} {LOCALE[appLocale].giftBoxWithShipping} <span className="shippingCostText"> {fees.shipping + (fees.handling * order.quantity)} {LOCALE[appLocale].currency}</span></label></div> : null;

        return (
            <div className="Combobox quantity-selectors" >
                {organizerId == 160 && product.priceAdult ? <div className="quantity-container">
                    <h4><input type="checkbox"
                        checked={order.isImax}
                        onChange={isImaxChanged} />{LOCALE[appLocale].imax}
                    </h4></div> : null}

                {product.priceAdult ? <div className="quantity-container">
                    <h4>{LOCALE[appLocale].adult}</h4>
                    <QuantitySelector quantity={order.adultQuantity}
                        quantityChanged={adultQuantityChanged}
                        minQuantity={product.minQuantity}
                        maxQuantity={product.maxQuantity}
                        incrementBy={product.incrementBy} /></div> : null}

                {product.priceChild ? <div className="quantity-container">
                    <h4>{LOCALE[appLocale].child}</h4>
                    <QuantitySelector quantity={order.childQuantity}
                        quantityChanged={childQuantityChanged}
                        minQuantity={product.minQuantity}
                        maxQuantity={product.maxQuantity}
                        incrementBy={product.incrementBy} /></div> : null}

                {digital}
            </div>
        )
    }
}