import React from 'react'
import _ from 'lodash'
import LOCALE from '../../AppLocale'
import CreditCards from './CreditCards'
import AcceptConditions from './AcceptConditions'
import CONSTANTS from '../checkout-constants'

export default class Payment extends React.Component {
    constructor() {
        super();

        this.creditCardChanged = this.creditCardChanged.bind(this);
        this.conditionsAcceptedChanged = this.conditionsAcceptedChanged.bind(this);
    }

    componentDidMount() {
        this.paymentChanged(this.props.payment);
    }

    paymentChanged(payment) {
        const { actions, payWithReepay } = this.props;
        payment.isValid = !payWithReepay ? payment.creditCard && payment.conditionsAccepted : payment.conditionsAccepted;
        actions.paymentChanged(payment);
    }

    creditCardChanged(creditCard) {
        let { payment } = this.props;
        payment.creditCard = creditCard;
        this.paymentChanged(payment);
    }

    conditionsAcceptedChanged(e) {
        const conditionsAccepted = e.target.checked;
        let { payment } = this.props;
        payment.conditionsAccepted = conditionsAccepted;
        this.paymentChanged(payment);
    }

    render() {
        const { appLocale, totalPrice, payment, creditCards, organizer, payWithReepay } = this.props;

        return (
            <div className="Payment">
                {!payWithReepay ? <h1>{LOCALE[appLocale].payment} <span className={payment.isValid ? 'required valid icon-check' : 'required'}>{payment.isValid ? CONSTANTS.valid : CONSTANTS.required}</span></h1> : null}
                {!payWithReepay ? <CreditCards appLocale={appLocale} totalPrice={totalPrice} creditCards={creditCards} selectedCreditCard={payment.creditCard} creditCardChanged={this.creditCardChanged} /> : null}
                <AcceptConditions appLocale={appLocale} organizer={organizer} isChecked={payment.conditionsAccepted} conditionsAcceptedChanged={this.conditionsAcceptedChanged} />
            </div>
        )
    }
}