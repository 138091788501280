import React from 'react'
import _ from 'lodash'
import LOCALE from '../../AppLocale'
import QuantitySelector from './QuantitySelector'

export default class GiftBox extends React.Component {

    render() {
        const { appLocale, order, fees, isDigitalChanged, quantityChanged } = this.props;
        const product = order.product;

        let digital = product.availableAsPhysical ? <div className="quantity-container digital"><label>
            {product.availableAsPhysical && product.availableAsDigital ? <input type="checkbox" checked={!order.isDigital} onChange={isDigitalChanged} /> : null}
            {digital} {LOCALE[appLocale].giftBoxWithShipping} <span className="shippingCostText">{fees.shipping + (fees.handling * order.quantity)} {LOCALE[appLocale].currency}</span></label></div> : null;

        return (
            <div className="GiftBox quantity-selectors">
                <div className="quantity-container">
                    <h4>{LOCALE[appLocale].quantity}</h4>
                    <QuantitySelector quantity={order.quantity}
                        quantityChanged={quantityChanged}
                        minQuantity={product.minQuantity}
                        maxQuantity={product.maxQuantity}
                        incrementBy={product.incrementBy} />
                </div>

                {digital}
            </div>
        )
    }
}