import './receipt.scss'

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { appHeightChanged } from '../../actions/app-actions'
import { resetCheckout } from '../../actions/checkout-actions'
import { resetOrder } from '../../actions/order-actions'
import { CATEGORIES } from '../products/product-constants'
import LOCALE from '../AppLocale'
import Voucher from './Voucher'
import Summary from '../summary/SummaryContainer'
import { baseUrl } from '../../api/webshop-api'
import { ROUTES } from '../navigation/route-constants'

class ReceiptContainer extends React.Component {
    timeout = 300000;
    timer = null;

    constructor() {
        super();
        this.state = { deactivateDownload: false };

        this.handleTimeout = this.handleTimeout.bind(this);
        this.downloadVouchers = this.downloadVouchers.bind(this);
    }

    componentDidMount() {
        this.timer = setTimeout(this.handleTimeout, this.timeout);

        // onLoad isn't triggered on <App in RECEIPT after payment, so we put an extra height check here after mount...
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId);
        else window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        const { actions } = this.props;
        clearTimeout(this.timer);
        actions.resetCheckout();
        actions.resetOrder();
    }

    handleTimeout() {
        this.setState({ deactivateDownload: true });
    }

    downloadVouchers() {
        const { receipt } = this.props;
        for (var i = 0; i < receipt.checkout.order.quantity; i++) {
            window.open(baseUrl + 'webshop/download-vouchers?orderId=' + receipt.checkout.id + i);
        }
    }

    generateVouchers(vouchers, showPrice) {
        return vouchers.map((voucher, i) => {
            return <Voucher key={i} index={i} voucher={voucher} showPrice={showPrice} />
        })
    }

    render() {
        const { deactivateDownload } = this.state;
        const { appLocale, receipt, showPrice, isCinemaxx, customerName, organizer, additionalHtml, history, salesChannel } = this.props;
        const vouchers = isCinemaxx && !receipt.checkout.shipping ? this.generateVouchers(receipt.vouchers, showPrice) : null;
        const product = isCinemaxx ? (!receipt.checkout.shipping ? <p>{LOCALE[appLocale].digitalOrder}:</p> : <p>{LOCALE[appLocale].physicalOrder}</p>) : null;
        const download = !receipt.checkout.shipping ? <input type="button" disabled={deactivateDownload} className="gray" value={LOCALE[appLocale].download} onClick={this.downloadVouchers} /> : null;
        const print = !isCinemaxx ? <input type="button" className="gray" value={LOCALE[appLocale].print} onClick={() => window.print()} /> : null;
        const channel = salesChannel ? `&saleschannel=${salesChannel}` : '';

        return (
            <div className="Receipt">
                <h1>{LOCALE[appLocale].receipt}</h1>
                <hr />

                <h3>{LOCALE[appLocale].dear} {customerName}</h3>
                <p>{LOCALE[appLocale].thankYouText} {organizer.name}.</p>

                <Summary />

                <p>
                    {LOCALE[appLocale].emailText}<br />
                    {download ? <span>{LOCALE[appLocale].downloadText}</span> : LOCALE[appLocale].physicalOrder}
                </p>

                <p>{LOCALE[appLocale].orderNumber}: {receipt.checkout.id}</p>

                {download}
                {print}
                <input type="button" className="gray back" onClick={() => history.push(`${ROUTES.HOME}?org=${organizer.id}${channel}`)} value={LOCALE[appLocale].startOver} />

                {product}
                {vouchers}
                <div dangerouslySetInnerHTML={{ __html: additionalHtml }}></div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    if (!state.checkout.completed) ownProps.history.replace('/');

    return {
        appLocale: state.app.locale,
        iframeId: state.app.iframeId,
        receipt: state.checkout.receipt || { checkout: {}, vouchers: [] },
        showPrice: state.order.product.category == CATEGORIES.GIFTCARD,
        isCinemaxx: state.webshop.organizer.id == 160,
        customerName: state.checkout.customer.name || "",
        organizer: state.webshop.organizer,
        salesChannel: state.webshop.salesChannel ? `&saleschannel=${state.webshop.salesChannel}` : '',
        additionalHtml: state.webshop.receiptHtml,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ resetCheckout, resetOrder, appHeightChanged }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptContainer)