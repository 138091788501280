import React from 'react'
import _ from 'lodash'
import LOCALE from '../../AppLocale'
import { CATEGORIES } from '../product-constants'
import GiftBox from './GiftBox'
import Combobox from './Combobox'
import GiftCard from './GiftCard'
import Message from './Message'
import { amountChanged } from '../../../actions/order-actions'

export default class Product extends React.Component {

    render() {
        const { appLocale, order, fees, adultQuantityChanged, childQuantityChanged, quantityChanged, amountChanged, isImaxChanged, includeMessage, messageChanged, isDigitalChanged } = this.props;
        const product = order.product;
        let selectors = null;

        switch (order.product.category) {
            case CATEGORIES.GIFTBOX:
                selectors = <GiftBox appLocale={appLocale}
                    order={order}
                    fees={fees}
                    isDigitalChanged={isDigitalChanged}
                    quantityChanged={quantityChanged}
                />;
                break;
            case CATEGORIES.GIFTCARD:
                selectors = <GiftCard appLocale={appLocale}
                    order={order}
                    fees={fees}
                    isDigitalChanged={isDigitalChanged}
                    quantityChanged={quantityChanged}
                    amountChanged={amountChanged}
                />;
                break;
            case CATEGORIES.COMBOBOX:
                selectors = <Combobox appLocale={appLocale}
                    order={order}
                    fees={fees}
                    isDigitalChanged={isDigitalChanged}
                    adultQuantityChanged={adultQuantityChanged}
                    childQuantityChanged={childQuantityChanged}
                    isImaxChanged={isImaxChanged}
                />;
                break;
        }

        return (
            <div className="Product" >
                <div className="img-quantity">
                    <div><img src={product.image} alt={`${LOCALE[appLocale].giftBoxWithShipping} ` + product.name} /></div>
                    {selectors}
                </div>
                <div className="product-info">
                    <h1>{product.name}</h1>
                    <p dangerouslySetInnerHTML={{ __html: product.description }}></p>
                </div>
                {includeMessage ? <Message appLocale={appLocale} message={order.message} messageChanged={messageChanged} /> : null}
            </div>
        )
    }
}