import React from 'react'
import { connect } from 'react-redux'
import LOCALE from '../AppLocale'

class Spinner extends React.Component {

    render() {
        const { appLocale } = this.props;

        return (
            <div className={this.props.show ? 'Spinner overlay fade-in' : 'Spinner overlay fade-out'}>
                <p className="overlay-content">{LOCALE[appLocale].loading}</p>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        show: state.webshop.isFetching || state.checkout.isFetching || ownProps.show || false,
        appLocale: state.app.locale
    }
}

export default connect(mapStateToProps)(Spinner)