import React from 'react'
import LOCALE from '../../AppLocale'

export default class AcceptConditions extends React.Component {

    render() {
        const { appLocale, organizer, isChecked, conditionsAcceptedChanged } = this.props;
        
        return (
            <div className="AcceptConditions">
                <label>
                    <input type="checkbox" defaultChecked={isChecked} onChange={conditionsAcceptedChanged} />
                    <span dangerouslySetInnerHTML={{ __html: LOCALE[appLocale].acceptConditions.replace('INSERT_ORGANIZER_NAME', organizer.name).replace('INSERT_ORGANIZER_CONDITIONS', organizer.saleConditionsUrl) }}></span>
                </label>
            </div>
        )
    }
}