import './summary.scss'

import { connect } from 'react-redux'
import _ from 'lodash'
import Summary from './Summary'

const mapStateToProps = (state, ownProps) => {
    const designName = state.order.designId && !state.order.isDigital ?
        _.find(state.webshop.designs, (design) => { return design.id == state.order.designId }).name : null

    return {
        appLocale: state.app.locale,
        order: state.order,
        totalPrice: state.order.totalPrice,
        designName,
        transactionFee: state.webshop.fees.transaction
    }
}

export default connect(mapStateToProps)(Summary)