import React from 'react'

const Error = ({ title, error, buttons }) => (
    <div className="Error overlay">
        <div className="overlay-content">
            <h3>{title}</h3>
            <p>{error}</p>
            {buttons}
        </div>
    </div>
)

export default Error