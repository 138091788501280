import React from 'react'
import _ from 'lodash'
import LOCALE from '../AppLocale'
import * as validators from '../../utilities/validation-utilities'
import CONSTANTS from './checkout-constants'
import AcceptConditions from './payment/AcceptConditions'

export default class Customer extends React.Component {
    constructor(props) {
        super(props);

        const customer = props.customer;
        this.state = {
            isValid: {
                name: customer.name ? CONSTANTS.valid : CONSTANTS.required,
                phone: validators.isValidPhone(customer.phone, props.organizer.minimumPhoneDigits) ? CONSTANTS.valid : CONSTANTS.required,
                email: validators.isValidEmail(customer.email) ? CONSTANTS.valid : CONSTANTS.required,
                repeatEmail: validators.isValidEmail(customer.repeatEmail) && customer.email === customer.repeatEmail ? CONSTANTS.valid : CONSTANTS.required
            },
            name: customer.name ? customer.name : '',
            phone: customer.phone ? customer.phone : '',
            email: customer.email ? customer.email : '',
            repeatEmail: customer.repeatEmail ? customer.repeatEmail : ''
        }

        this.nameChanged = this.nameChanged.bind(this);
        this.phoneChanged = this.phoneChanged.bind(this);
        this.emailChanged = this.emailChanged.bind(this);
        this.repeatEmailChanged = this.repeatEmailChanged.bind(this);
        this.rememberMeChanged = this.rememberMeChanged.bind(this);
        this.customerChanged = this.customerChanged.bind(this);

        this.conditionsAcceptedChanged = this.conditionsAcceptedChanged.bind(this);
    }

    componentDidMount() {
        this.customerChanged();
        this.paymentChanged(this.props.payment);
    }

    customerChanged() {
        const customer = this.state;
        const { organizer } = this.props;
        const isValid = customer.name !== '' && validators.isValidPhone(customer.phone, organizer.minimumPhoneDigits) && validators.isValidEmail(customer.email) &&
            validators.isValidEmail(customer.repeatEmail) && customer.email === customer.repeatEmail;

        this.props.actions.customerChanged({
            name: customer.name,
            phone: customer.phone,
            email: customer.email,
            repeatEmail: customer.repeatEmail,
            isValid
        });
    }

    nameChanged(e) {
        const name = e.target.value;
        const isValid = name ? CONSTANTS.valid : CONSTANTS.required;
        this.setState({ name, isValid: { ...this.state.isValid, name: isValid } });
    }

    phoneChanged(e) {
        const { organizer } = this.props;
        let phone = e.target.value;
        const nonNumericRegex = /[^0-9.]+/g;
        phone = phone.replace(nonNumericRegex, '');

        const isValid = validators.isValidPhone(phone, organizer.minimumPhoneDigits) ? CONSTANTS.valid : CONSTANTS.required;
        this.setState({ phone, isValid: { ...this.state.isValid, phone: isValid } });
    }

    emailChanged(e) {
        const email = e.target.value;
        const isValid = validators.isValidEmail(email) ? CONSTANTS.valid : CONSTANTS.required;
        this.setState({ email, isValid: { ...this.state.isValid, email: isValid } });
    }

    repeatEmailChanged(e) {
        const repeatEmail = e.target.value;
        const isValid = validators.isValidEmail(repeatEmail) && this.state.email === repeatEmail ? CONSTANTS.valid : CONSTANTS.required;
        this.setState({ repeatEmail, isValid: { ...this.state.isValid, repeatEmail: isValid } });
    }

    rememberMeChanged(e) {
        const checked = e.target.checked;
        this.props.actions.rememberMeChanged(checked);
    }
        
    conditionsAcceptedChanged(e) {
        const conditionsAccepted = e.target.checked;
        let { payment } = this.props;
        payment.conditionsAccepted = conditionsAccepted;
        this.paymentChanged(payment);
    }

    paymentChanged(payment) {
        const { actions } = this.props;
        payment.isValid = payment.conditionsAccepted;
        actions.paymentChanged(payment);
    }

    render() {
        const { rememberMe, appLocale, payWithReepay, organizer, payment } = this.props;
        const { name, phone, email, repeatEmail, isValid } = this.state;

        return (
            <div className="Customer">
                <h1>{LOCALE[appLocale].yourInformation}</h1>
                <div className="textbox">
                    <label>{LOCALE[appLocale].name}</label><input type="text" value={name} onChange={this.nameChanged} onBlur={this.customerChanged} />
                    <span className={isValid.name === CONSTANTS.valid ? 'required valid icon-check': 'required'}>{isValid.name}</span>
                </div>
                <div className="textbox">
                    <label>{LOCALE[appLocale].phone}</label><input type="tel" value={phone} onChange={this.phoneChanged} onBlur={this.customerChanged} />
                    <span className={isValid.phone === CONSTANTS.valid ? 'required valid  icon-check' : 'required'}>{isValid.phone}</span>
                </div>
                <div className="textbox">
                    <label>{LOCALE[appLocale].email}</label><input type="email" value={email} onChange={this.emailChanged} onBlur={this.customerChanged} />
                    <span className={isValid.email === CONSTANTS.valid ? 'required valid  icon-check' : 'required'}>{isValid.email}</span>
                </div>
                <div className="textbox">
                    <label>{LOCALE[appLocale].repeatEmail}</label><input type="email" value={repeatEmail} onChange={this.repeatEmailChanged} onBlur={this.customerChanged} />
                    <span className={isValid.repeatEmail === CONSTANTS.valid ? 'required valid icon-check' : 'required'}>{isValid.repeatEmail}</span>
                </div>
                <label id="remember-me"><input type="checkbox" defaultChecked={rememberMe} onChange={this.rememberMeChanged} />{LOCALE[appLocale].rememberMe}</label>
                {payWithReepay ? <AcceptConditions appLocale={appLocale} organizer={organizer} isChecked={payment.conditionsAccepted} conditionsAcceptedChanged={this.conditionsAcceptedChanged} /> : null}
            </div>
        )
    }
}